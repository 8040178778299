import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import img8 from "../../assets/img/thumbnail/media/8.jpg";
import img9 from "../../assets/img/thumbnail/media/9.jpg";
import img10 from "../../assets/img/thumbnail/media/10.jpg";
import img11 from "../../assets/img/thumbnail/media/11.jpg";
import img12 from "../../assets/img/thumbnail/media/11.jpg";
import img13 from "../../assets/img/thumbnail/media/13.jpg";
import img14 from "../../assets/img/thumbnail/media/14.jpeg";
import img15 from "../../assets/img/thumbnail/media/15.jpeg";
import img16 from "../../assets/img/thumbnail/media/16.jpg";
import img17 from "../../assets/img/thumbnail/media/17.jpg";
import img18 from "../../assets/img/thumbnail/media/18.jpg";
import img19 from "../../assets/img/thumbnail/media/19.jpg";

import Gallery from "react-grid-gallery";
import ReactPlayer from "react-player/youtube";

const IMAGES = [
  {
    src: img8,
    thumbnail: img8,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img9,
    thumbnail: img9,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img10,
    thumbnail: img10,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img11,
    thumbnail: img11,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img12,
    thumbnail: img12,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img13,
    thumbnail: img13,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img14,
    thumbnail: img14,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img15,
    thumbnail: img15,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img16,
    thumbnail: img16,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img17,
    thumbnail: img17,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img18,
    thumbnail: img18,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img19,
    thumbnail: img19,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
];

export default class UpEvents extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2rem 0",
            }}
          >
            <ReactPlayer url="https://youtu.be/DURF-Rb-oEQ" controls={true} />
          </div>
          <Row>
            <Col lg="12" className="gallery-images">
              <Gallery images={IMAGES} rowHeight={300} maxRows={3} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import ReactPlayer from "react-player/youtube";

export class about extends Component {
  render() {
    return (
      <Container id="about">
        <Col md="12" className="section-title about-heading">
          <h2>About Us</h2>
        </Col>
        <Row>
          <Col xs="12" md="6" lg="6">
            <p>{this.props.data ? this.props.data.paragraph : "loading..."}</p>
            <h4>Our Reach</h4>
            <ul>
              <li>Lucknow</li>
              <li>Bihar</li>
              <li>Odissa</li>
              <li>Delhi</li>
            </ul>
          </Col>
          <Col xs="12" md="6" lg="6" className="about-img">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactPlayer
                url="https://www.youtube.com/watch?v=AODOwKdQvaw"
                controls={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default about;

import React, { Component } from "react";
import { Container, Col, Row, UncontrolledCarousel } from "reactstrap";

import land1 from "../../assets/img/plant.jpg";
import land2 from "../../assets/img/8.jpg";
import land3 from "../../assets/img/landing3.jpg";
import land4 from "../../assets/img/2.jpg";
import land7 from "../../assets/img/5.jpg";
import land8 from "../../assets/img/orf.jpg";
import land9 from "../../assets/img/15.jpg";
import land5 from "../../assets/img/2.jpeg";
import land6 from "../../assets/img/lan1.jpg";

const landing_img = [
  {
    src: land6,
    altText: "internship",
    caption: " ",
    key: "8",
  },
  {
    src: land5,
    altText: "internship",
    caption: " ",
    key: "8",
  },
  {
    src: land7,
    altText: "internship",
    caption: " ",
    key: "8",
  },
  {
    src: land1,
    altText: "internship",
    caption: " ",
    key: "8",
  },
  {
    src: land2,
    altText: "Medical Camp",
    caption: " ",
    key: "2",
  },
  {
    src: land3,
    altText: "Medical Camp",
    caption: " ",
    key: "3",
  },
  {
    src: land4,
    altText: "internship",
    caption: " ",
    key: "8",
  },
  {
    src: land9,
    altText: "Medical Camp",
    caption: " ",
    key: "4",
  },
  {
    src: land8,
    altText: "Medical Camp",
    caption: " ",
    key: "1",
  },
];

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="overlay"></div>
        <UncontrolledCarousel
          className="intro"
          captionText=""
          items={landing_img}
        />
        <Container className="intro-text">
          <Row>
            <Col
              lg="12"
              sm="12"
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <h1>
                <span className="avian">Avian</span>
                <span className="for"> for</span>
                <span className="india"> India</span>
              </h1>
            </Col>
            <Col lg="12" sm="12">
              <p>{this.props.data ? this.props.data.paragraph : "Loading"}</p>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default Header;

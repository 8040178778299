import React from "react";
const PageHeader = (props) => (
  <>
    <div className="pageheader-landing">
      <div className="pageheader-bg">
        <div className="pageheader-overlay" />
        <div className="pageheader-img" />
        <div className="pageheader-content">
          <h2>{props.data}</h2>
        </div>
      </div>
    </div>
  </>
);

export default PageHeader;

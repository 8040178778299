import React from "react";
import Gallery from "./Gallery/Gallery";
import PageHeader from "../Components/common_section/PageHeader";
import MediaData from "../data/media.json";

export default class Advisors extends React.Component {
  state = {
    mediaPageData: {},
  };

  getlandingPageData() {
    this.setState({ mediaPageData: MediaData });
  }

  componentDidMount() {
    this.getlandingPageData();
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }

  handleScrollToAboutUs = () => {
    window.scrollTo(0, 100);
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <PageHeader data={"Picture Gallery"} />
          <Gallery data={this.state.mediaPageData.gallery} />
        </div>
      </>
    );
  }
}

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "./assets/css/demo.scss";

import Layout from "./hoc/Layout";
import Index from "./views/Index/Index";
import Donate from "./views/Donate";
import Happenings from "./views/Happenings";
import Trustees from "./views/Trustees";
import Events from "./views/Events";
import PicGallery from "./views/PicGallery";
import Gallery from "./views/Gallery/Gallery";
import Privacy from "./views/Privacy";
import Faq from "./views/Faq";
import Award from "./views/Awards";
import JoinUs from "./views/Join";
import Covid from "./views/Covid/Covid";
import Volunteer from "./Components/Volunteer/Volunteer";

ReactDOM.render(
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route path="/index" component={Index} />
        <Route path="/donate" component={Donate} />
        <Route path="/happenings" component={Happenings} />
        <Route path="/trustees" component={Trustees} />
        <Route path="/media_events" component={Events} />
        <Route path="/gallery" component={PicGallery} />
        <Route path="/fgallery" component={Gallery} />
        <Route path="/privacy_policy" component={Privacy} />
        <Route path="/faq" component={Faq} />
        <Route path="/award" component={Award} />
        <Route path="/join" component={JoinUs} />
        <Route path="/covid19" component={Covid} />
        <Route path="/volunteer" component={Volunteer} />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Layout>
  </BrowserRouter>,
  document.getElementById("root")
);

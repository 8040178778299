import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Jsondata from "../data/data.json";
const Awards = () => {
  const [awardsData, setAwardsData] = useState([]);
  useEffect(() => {
    setAwardsData(Jsondata.Awards);
  }, []);

  return (
    <div className="wrapper">
      <div className="pageheader-landing">
      <div className="pageheader-bg">
        <div className="pageheader-overlay" />
        <div className="pageheader-award-img" />
        <div className="pageheader-content">
          <h2>Award</h2>
        </div>
      </div>
    </div>
      <Container style={{ padding: "2rem 0" }}>
        <div className="awards-sec">
          <table>
            <thead>
              <tr>
                <th>S.no</th>
                <th>Title</th>
                <th>Issuer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {awardsData.map((award, index) => {
                  return (
                    <React.Fragment key={index}>
                      <td>{award.sno}</td>
                      <td>{award.title}</td>
                      <td>{award.org}</td>
                    </React.Fragment>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};

export default Awards;

import React from "react";
import PageHeader from "../Components/common_section/PageHeader";
import TrusteesMembers from "../Components/member_section/trustees";
import Jsondata from "../data/data.json";

export default class Trustees extends React.Component {
  state = {
    landingPageData: {},
  };

  getlandingPageData() {
    this.setState({ landingPageData: Jsondata });
  }
  componentDidMount() {
    this.getlandingPageData();
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }

  handleScrollToAboutUs = () => {
    window.scrollTo(0, 100);
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <PageHeader data={"Board Of Trustees"} />
          <TrusteesMembers data={this.state.landingPageData.TrusteesMembers} />
        </div>
      </>
    );
  }
}

import React from "react";
import {Container, Row, Col} from "reactstrap";
import {covid, vccm, vccb} from "../Gallery/Imports";
import ImageGallery from "react-image-gallery";
import "./covid.scss";
import ReactPlayer from "react-player/youtube";

const Covid = () => {
	return (
		<Container style={{padding: "2rem 0"}}>
			<div className="section-title">
				<h2 style={{textAlign: "center"}}>Project - Sanjivani</h2>
			</div>

			<p style={{fontFamily: "Cantarell, sans-serif"}}>
				"In the very first week that India entered the lockdown in March
				2020 to curb the novel coronavirus disease (COVID-19) pandemic,
				Prime Minister Narendra Modi called on non-government organisations
				(NGO) to help the government — by providing basic necessities to the
				underprivileged, by supplying medical and protective gear and
				assisting with awareness campaigns on social distancing." - (source:
				www.downtoearth.org.in)
			</p>
			<p style={{fontWeight: "bold"}}>
				AVIAN FOR INDIA came forward to do its part. Helping the people in
				every way possible. Starting from performing RT-PCR tests, to
				providing free vaccination. Following is the case study of how Avian
				For India remained firm in fighting the COVID-19 virus.
			</p>

			<Row className="phase">
				<Col sm="12" lg="12">
					<h1>Phase 1</h1>
					<p>Date: 16th Dec 2020</p>
				</Col>
				<Col lg="5" sm="12">
					<ImageGallery
						showNav={false}
						autoPlay={true}
						slideInterval={6000}
						showPlayButton={false}
						items={covid}
						showFullscreenButton={false}
						showThumbnails={true}
						thumbnailPosition={"left"}
					/>
				</Col>
				<Col lg="7" sm="12">
					<h2>RT-PCR Testing (Race Course - Lucknow)</h2>
					<p>In collaboration with UPHC, Sadar Lucknow</p>
					<h3>Impact</h3>
					<ul>
						<li>1200 RT-PCR Testings</li>
					</ul>
				</Col>
			</Row>

			<Row className="phase">
				<Col sm="12" lg="12">
					<h1>Phase 2</h1>
					<p>Date: 17th May - 20th May 2021</p>
				</Col>
				<Col lg="5" sm="12">
					<ImageGallery
						showNav={false}
						autoPlay={true}
						slideInterval={6000}
						showPlayButton={false}
						items={vccb}
						showFullscreenButton={false}
						showThumbnails={true}
						thumbnailPosition={"left"}
					/>
				</Col>
				<Col lg="7" sm="12">
					<h2>Vaccination Camp (Mainatand, West Champaran, Bihar) </h2>
					<p>In collaboration with UPHC, Bihar</p>
					<h3>Impact</h3>
					<ul>
						<li>1500 People were vaccinated</li>
					</ul>
				</Col>
			</Row>

			<Row className="phase">
				<Col sm="12" lg="12">
					<h1>Phase 3</h1>
					<p>Date: 25th June - 31st July 2021</p>
				</Col>
				<Col lg="6" sm="12">
					<ReactPlayer
						width={"100%"}
						height="100%"
						style={{aspectRatio: "16 / 9"}}
						url="https://www.youtube.com/watch?v=AODOwKdQvaw"
						controls={true}
					/>
				</Col>
				<Col lg="6" sm="12">
					<h2>Vaccination Camp (Gurudwara Sadar, Lucknow) </h2>
					<p>
						In collaboration with Gurudwada Committee, Sadar Lucknow;
						UPHC, Lucknow
					</p>
					<h3>Impact</h3>
					<ul>
						<li>20,350 People were vaccinated</li>
						<li>5000+ RT-PCR testing</li>
					</ul>
				</Col>
			</Row>

			<Row className="phase">
				<Col sm="12" lg="12">
					<h1>Phase 4</h1>
					<p>Date: 1st Aug - 2nd Nov 2021</p>
				</Col>
				<Col lg="5" sm="12">
					<ImageGallery
						showNav={false}
						autoPlay={true}
						slideInterval={6000}
						showPlayButton={false}
						items={vccm}
						showFullscreenButton={false}
						showThumbnails={true}
						thumbnailPosition={"left"}
					/>
				</Col>
				<Col lg="7" sm="12">
					<h2>
						Vaccination Camp (Malak, Nilmatha | Doordarshan kendra,
						Lucknow){" "}
					</h2>
					<p>
						In collaboration with Gurudwada Committee, Sadar Lucknow;
						UPHC, Lucknow
					</p>
					<h3>Impact</h3>
					<ul>
						<li>1.8 Lakh people were vaccinated</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
};

export default Covid;

import React, { useEffect, useState } from "react";
import UpEvents from "../Components/events_section/media_event";
import PageHeader from "../Components/common_section/PageHeader";
import Jsondata from "../data/data";

const Events = () => {
  const [landingPageData, setLandingPageData] = useState([]);

  useEffect(() => {
    setLandingPageData(Jsondata.Events);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <div className="wrapper">
        <PageHeader data={"Media & Events"} />
        <UpEvents data={landingPageData} />
      </div>
    </>
  );
};

export default Events;

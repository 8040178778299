import React, {Component} from "react";
import {Col, Row, Container} from "reactstrap";
import ReactPlayer from "react-player/youtube";

export class events_list extends Component {
	render() {
		return (
			<div id="features" className="text-center">
				<Container>
					<Col md="12" className="section-title">
						<h2>Events List</h2>
					</Col>
					<Row className="event-list">
						<table>
							<thead>
								<tr>
									<th>S.no</th>
									<th>Events And Activities</th>
									<th>Location</th>
									<th>Date</th>
								</tr>
							</thead>
							<tbody>
								{this.props.data
									? this.props.data.map((d, i) => (
											<tr key={i }>
												<td>{i + 1}</td>
												<td>{d.event}</td>
												<td>{d.location}</td>
												<td>{d.date}</td>
											</tr>
									  ))
									: null}
							</tbody>
						</table>
					</Row>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "2rem 0",
						}}
					>
						<ReactPlayer
							url="https://youtu.be/DURF-Rb-oEQ"
							controls={true}
						/>
					</div>
				</Container>
			</div>
		);
	}
}

export default events_list;

import React, { useState } from "react";
import { MdEventAvailable, MdHome } from "react-icons/md";
import { FaQuestion, FaAward, FaCamera } from "react-icons/fa";
import { BiDonateHeart } from "react-icons/bi";
import { FaHandshake } from "react-icons/fa";
import {
  RiTeamFill,
  RiSignalTowerFill,
  RiInstagramLine,
  RiYoutubeFill,
  RiTwitterFill,
  RiVirusLine,
  RiFacebookCircleFill,
} from "react-icons/ri";
import { HiTemplate } from "react-icons/hi";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  UncontrolledTooltip,
} from "reactstrap";

import { Link, NavLink } from "react-router-dom";

function IndexNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <div
        className="social-media"
        style={{
          padding: "0 1rem",
          display: "flex",
          background: "rgb(224 224 224)",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ color: "#313131", fontSize: 12, fontWeight: "bold" }}>
          <i className="fa fa-registered" aria-hidden="true"></i> Reg. no:
          140/20
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a
            className="social-link"
            href="https://instagram.com/avianforindia/"
            target="_blank"
            rel="noopener noreferrer"
            id="instagram-tooltip"
          >
            <RiInstagramLine size="22" className="social" color="#8a3ab9" />
          </a>
          <UncontrolledTooltip target="#instagram-tooltip">
            Follow us on Instagram
          </UncontrolledTooltip>

          <a
            className="social-link"
            href="https://twitter.com/AvianIndia"
            target="_blank"
            rel="noopener noreferrer"
            id="twitter-tooltip"
          >
            <RiTwitterFill size="22" className="social" color="#1DA1F2" />
          </a>
          <UncontrolledTooltip target="#twitter-tooltip">
            Follow us on Twitter
          </UncontrolledTooltip>

          <a
            className="social-link"
            href="https://www.facebook.com/avianforindiatrust/"
            target="_blank"
            rel="noopener noreferrer"
            id="facebook-tooltip"
          >
            <RiFacebookCircleFill
              size="22"
              className="social"
              color="#4267B2"
            />
          </a>
          <UncontrolledTooltip target="#facebook-tooltip">
            Like us on Facebook
          </UncontrolledTooltip>

          <a
            href="https://www.youtube.com/channel/UCm6F0rrixbVSxsQ69szLJ1Q"
            target="_blank"
            rel="noopener noreferrer"
            id="youtube-tooltip"
          >
            <RiYoutubeFill className="social" size="22" color="#FF0000" />
          </a>
          <UncontrolledTooltip target="#youtube-tooltip">
            Subscribe us on Youtube
          </UncontrolledTooltip>
        </div>
      </div>
      <Navbar
        color="light"
        light
        expand="md"
        style={{ justifyContent: "space-around" }}
      >
        <div style={{ display: "flex" }}>
          <NavbarBrand href="/index" id="navbar-brand">
            <img
              style={{ width: 200 }}
              src={require("../../assets/img/logo.png")}
              alt="..."
            />
          </NavbarBrand>
          <UncontrolledTooltip target="#navbar-brand">
            With Glaring Aim
          </UncontrolledTooltip>
          <NavbarToggler onClick={toggle} />
        </div>
        <Collapse className="justify-content-end" isOpen={isOpen} navbar>
          <Nav navbar>
            <NavItem onClick={toggle}>
              <NavLink
                to="/index"
                className="nav-link"
                activeclassname="active"
              >
                <MdHome color="#e74091" />
                Home
              </NavLink>
            </NavItem>
            <NavItem onClick={toggle}>
              <NavLink
                to="/covid19"
                className="nav-link"
                activeclassname="active"
              >
                <RiVirusLine color="#e74091" />
                COVID-19
              </NavLink>
            </NavItem>

            <NavItem onClick={toggle}>
              <NavLink
                to="/happenings"
                className="nav-link"
                activeclassname="active"
              >
                <MdEventAvailable color="#e74091" />
                Events
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav>
              <DropdownToggle caret color="default" nav>
                <RiTeamFill color="#e74091" />
                Members
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={toggle}>
                  <Link to="/trustees">Board of trustees</Link>
                </DropdownItem>
                <DropdownItem onClick={toggle}>
                  <Link to="/volunteer">Volunteers</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle caret color="default" nav>
                <HiTemplate color="#e74091" />
                Resources
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={toggle}>
                  <Link to="/fgallery">
                    <FaCamera color="#e74091" />
                    Gallery
                  </Link>
                </DropdownItem>
                <DropdownItem onClick={toggle}>
                  <Link to="/faq">
                    <FaQuestion color="#e74091" />
                    FAQs
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem onClick={toggle}>
              <NavLink
                to="/media_events"
                className="nav-link"
                activeclassname="active"
              >
                <RiSignalTowerFill color="#e74091" />
                Media
              </NavLink>
            </NavItem>
            <NavItem onClick={toggle}>
              <NavLink to="/join" className="nav-link" activeclassname="active">
                <FaHandshake color="#e74091" />
                JoinUs
              </NavLink>
            </NavItem>
            <NavItem onClick={toggle}>
              <NavLink
                to="/award"
                className="nav-link"
                activeclassname="active"
              >
                <FaAward color="#e74091" />
                Award
              </NavLink>
            </NavItem>
            <NavItem onClick={toggle}>
              <NavLink
                to="/donate"
                id="donate"
                className="nav-link"
                activeclassname="active"
              >
                <BiDonateHeart color="#e74091" />
                Donate
              </NavLink>
              <UncontrolledTooltip target="#donate">
                Support The Cause
              </UncontrolledTooltip>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default IndexNavbar;

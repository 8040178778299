import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import PageHeader from "../../Components/common_section/PageHeader";
import {
  yoga,
  internship,
  vcc,
  cdn,
  skl,
  plnt,
  bld,
  env,
  covid,
  vccb,
  pop,
  musk,
  tpe,
  cdds,
} from "./Imports";
import classnames from "classnames";

const Gallery = () => {
  const [images, setImages] = useState(cdn);
  const [activeEvent, setEvent] = useState("cdn");
  const handleEvent = (event) => {
    if (event === "cdn") {
      setImages(cdn);
      setEvent("cdn");
    } else if (event === "skl") {
      setImages(skl);
      setEvent("skl");
    } else if (event === "plnt") {
      setImages(plnt);
      setEvent("plnt");
    } else if (event === "bld") {
      setImages(bld);
      setEvent("bld");
    } else if (event === "vcc") {
      setImages(vcc);
      setEvent("vcc");
    } else if (event === "env") {
      setImages(env);
      setEvent("env");
    } else if (event === "covid") {
      setImages(covid);
      setEvent("covid");
    } else if (event === "vccb") {
      setImages(vccb);
      setEvent("vccb");
    } else if (event === "pop") {
      setImages(pop);
      setEvent("pop");
    } else if (event === "musk") {
      setImages(musk);
      setEvent("musk");
    } else if (event === "yog") {
      setImages(yoga);
      setEvent("yog");
    } else if (event === "internship") {
      setImages(internship);
      setEvent("internship");
    } else if (event === "tpe") {
      setImages(tpe);
      setEvent("tpe");
    } else if (event === "cdds") {
      setImages(cdds);
      setEvent("cdds");
    }
  };

  return (
    <div id="wrapper">
      <PageHeader data={"Gallery"} />
      <Container className="container_section">
        <Nav tabs>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "tpe" })}
              onClick={() => {
                handleEvent("tpe");
              }}
            >
              Tree Plantation (2023)
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "internship" })}
              onClick={() => {
                handleEvent("internship");
              }}
            >
              Internship
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "yog" })}
              onClick={() => {
                handleEvent("yog");
              }}
            >
              Yoga Day
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "musk" })}
              onClick={() => {
                handleEvent("musk");
              }}
            >
              Project Muskurahat
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "vcc" })}
              onClick={() => {
                handleEvent("vcc");
              }}
            >
              Vaccination - Lko
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "vccb" })}
              onClick={() => {
                handleEvent("vccb");
              }}
            >
              Vaccination - Bihar
            </NavLink>
          </NavItem>

          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "pop" })}
              onClick={() => {
                handleEvent("pop");
              }}
            >
              World Population Day
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "plnt" })}
              onClick={() => {
                handleEvent("plnt");
              }}
            >
              Plantation
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "cdn" })}
              onClick={() => {
                handleEvent("cdn");
              }}
            >
              Clothes Donation
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "bld" })}
              onClick={() => {
                handleEvent("bld");
              }}
            >
              Blood Camp
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "covid" })}
              onClick={() => {
                handleEvent("covid");
              }}
            >
              RT-PCR Testing
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "env" })}
              onClick={() => {
                handleEvent("env");
              }}
            >
              World Evironment Day
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "skl" })}
              onClick={() => {
                handleEvent("skl");
              }}
            >
              Dist. of Books and Pencils
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activeEvent === "cdds" })}
              onClick={() => {
                handleEvent("cdds");
              }}
            >
              Winter Clothes Donation Drive
            </NavLink>
          </NavItem>
        </Nav>
        <Row>
          <Col lg="12" sm="12">
            <div className="gallery_card">
              <ImageGallery
                showNav={true}
                autoPlay={true}
                slideInterval={6000}
                showPlayButton={false}
                items={images}
                showThumbnails={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Gallery;

import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
// Load the AWS SDK for Node.js
var AWS = require("aws-sdk");
AWS.config.update({ region: "us-west-2" });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "us-west-2:b5724e7d-16c5-426d-9461-514e024328d3",
});

export class Contact extends Component {
  render() {
    return (
      <>
        <div id="contact">
          <Container>
            <Row>
              <Col
                lg="4"
                md="4"
                sm="12"
                style={{
                  height: "80%",
                  display: "flex",
                  alignSelf: "center",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "space-around",
                  borderRight: "1px solid white",
                }}
                className="contact-info"
              >
                <div className="contact-item">
                  <h3 style={{ marginTop: 0 }}>Registered With</h3>
                </div>

                <img
                  style={{ width: "100%", margin: "10px 0" }}
                  src={require("../../assets/img/niti.jpg")}
                  alt="niti"
                />

                <img
                  style={{ width: "100%", margin: "10px 0" }}
                  src={require("../../assets/img/mca.jpg")}
                  alt="mca"
                />

                <img
                  style={{ width: "100%", margin: "10px 0" }}
                  src={require("../../assets/img/msme.jpg")}
                  alt="msme"
                />
              </Col>
              <Col md="6" lg="6" sm="12" className="contact-info">
                <div className="contact-item">
                  <h3>Contact Info</h3>
                  <p>
                    <span>
                      <i className="fa fa-map-marker"></i> Address
                    </span>
                    {this.props.data ? this.props.data.address : "loading"}
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-phone"></i> Phone
                    </span>{" "}
                    <span>
                      Whatsapp :{" "}
                      {this.props.data ? this.props.data.phone1 : "loading"}
                    </span>
                    <span>
                      Calling :{" "}
                      {this.props.data ? this.props.data.phone2 : "loading"}
                    </span>
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fas fa-envelope"></i> Email
                    </span>{" "}
                    {this.props.data ? this.props.data.email : "loading"}
                  </p>
                </div>
              </Col>

              <Col lg="2" md="12">
                <Row>
                  <div className="social">
                    <h3>Social Media </h3>
                    <ul>
                      <li>
                        <a
                          href={
                            this.props.data ? this.props.data.facebook : "/"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            this.props.data ? this.props.data.instagram : "/"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={this.props.data ? this.props.data.twitter : "/"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={this.props.data ? this.props.data.youtube : "/"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Contact;

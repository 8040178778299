import React from "react";
import AboutUs from "../../Components/index_sections/about";
import Features from "../../Components/index_sections/features";
import Founder from "../../Components/index_sections/founder";
import Gallery from "../../Components/index_sections/gallery";
import Header from "../../Components/index_sections/header";
import Services from "../../Components/index_sections/services";
import Testimonials from "../../Components/testimonial/Testimonials";
import Collaborators from "../../Components/collab/collaborators";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import JsonData from "../../data/data.json";
import "./index.scss";

export default class Index extends React.Component {
  state = {
    landingPageData: {},
  };

  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }

  handleScrollToAboutUs = () => {
    window.scrollTo(0, 100);
  };

  render() {
    return (
      <>
        <div className="index-wrapper wrapper">
          <Header data={this.state.landingPageData.Header} />
          <AboutUs data={this.state.landingPageData.About} />
          <Founder />
          <Features data={this.state.landingPageData.Features} />
          <Services data={this.state.landingPageData.Services} />
          <Gallery />
          <Testimonials />
          <Collaborators />
        </div>
      </>
    );
  }
}

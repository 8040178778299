import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

function DefaultFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col lg="8" sm="12">
              <div className="copyright" id="copyright">
                © {new Date().getFullYear()} All Right Reserved, Designed by{" "}
                <a href="/index">Avian For India</a>. Coded by{" "}
                <a
                  href="https://anuj.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Anuj Kumar
                </a>
                .
              </div>
            </Col>
            <Col
              lg="4"
              sm="12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link style={{ marginRight: 10 }} to="/privacy_policy">
                Privacy policy
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;

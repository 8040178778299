import React, { useEffect, useState } from "react";
import Contact from "../Components/common_section/contact";
import Navigation from "../Components/common_section/Navigation";
import Footer from "../Components/common_section/DefaultFooter";
import JsonData from "../data/data.json";

const Layout = ({ children }) => {
  const [landingPageData, setlandingPageData] = useState({});
  useEffect(() => {
    setlandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      {children}
      <Contact data={landingPageData.Contact} />
      <Footer />
    </div>
  );
};

export default Layout;

import React, { useState } from "react";
import {
  Jumbotron,
  Button,
  Container,
  ButtonGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import image from "../../assets/img/avian-logo-sm.png";

function loadscript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const PayOptions = (props) => {
  const [rSelected, amount] = useState(null);

  async function displayRazorpay(amount) {
    const res = await loadscript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const pay_amount = parseInt(amount);
    const options = {
      key: "rzp_live_GSSCTRa3eYPIdx", // Enter the Key ID generated from the Dashboard
      amount: pay_amount * 100,
      currency: "INR",
      name: "Donation",
      description: "Thanks for Donating.",
      image: { image },
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {},
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  }

  return (
    <div className="pay-options">
      <Container>
        <Jumbotron>
          <img style={{float: 'left'}} alt="logo" src={image} />
          <h1 className="display-3">TRANSFORMING LIVES</h1>
          <p className="lead">
            100% of your donations is used in the transformation of the lifes of
            those in need.
          </p>
          <hr className="my-2" />

          <p>
            You can Donate to our organisation via Google Pay. Phone number for
            the same is provided below
          </p>
          <Button color="primary">Google Pay - 8960416311</Button>
          <hr />
          <h3>Donate Online</h3>
          <ButtonGroup>
            <Row>
              <Col>
                <Button
                  className="amt-opt"
                  color="primary"
                  onClick={() => amount(100)}
                  active={rSelected === 1}
                >
                  ₹ 100
                </Button>
              </Col>
              <Col>
                <Button
                  className="amt-opt"
                  color="primary"
                  onClick={() => amount(200)}
                  active={rSelected === 2}
                >
                  ₹ 200
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="amt-opt"
                  color="primary"
                  onClick={() => amount(500)}
                  active={rSelected === 3}
                >
                  ₹ 500
                </Button>
              </Col>
              <Col>
                <Button
                  className="amt-opt"
                  color="primary"
                  onClick={() => amount(1000)}
                  active={rSelected === 4}
                >
                  ₹ 1000
                </Button>
              </Col>
            </Row>
          </ButtonGroup>
          <Input
            className="amt-in"
            type="number"
            name="amount"
            onChange={(e) => amount(e.target.value)}
            id="amount"
            placeholder="Enter Amount"
          />
          <h4>Donate: ₹ {rSelected}</h4>
          <Button onClick={() => displayRazorpay(rSelected)} color="success">
            Proceed Payment
          </Button>
        </Jumbotron>
      </Container>
    </div>
  );
};

export default PayOptions;

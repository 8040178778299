import React, { useState } from "react";
import { Collapse, Button, CardBody, Card, Row, Container } from "reactstrap";
import PageHeader from "../Components/common_section/PageHeader";

const CoronaPeriod = () => {
  const [isOpenFirst, setIsOpenFirst] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [isOpenThird, setIsOpenThird] = useState(false);
  const [isOpenFourth, setIsOpenFourth] = useState(false);
  const [isOpenFifth, setIsOpenFifth] = useState(false);
  const [isOpenSixth, setIsOpenSixth] = useState(false);
  const [isOpenSeventh, setIsOpenSeventh] = useState(false);

  const toggleFirst = () => setIsOpenFirst(!isOpenFirst);
  const toggleSecond = () => setIsOpenSecond(!isOpenSecond);
  const toggleThird = () => setIsOpenThird(!isOpenThird);
  const toggleFourth = () => setIsOpenFourth(!isOpenFourth);
  const toggleFifth = () => setIsOpenFifth(!isOpenFifth);
  const toggleSixth = () => setIsOpenSixth(!isOpenSixth);
  const toggleSeventh = () => setIsOpenSeventh(!isOpenSeventh);

  return (
    <div className="wrapper">
      <PageHeader data={"FAQ"} />
      <Container id="faq">
        <Row className="c-work-left justify-content-start">
          <i
            className="c-work-even-icon fas fa-hand-point-right"
            aria-hidden="true"
          ></i>
          <Button color="primary" onClick={toggleFirst}>
            What is Avian For India?
          </Button>
          <Collapse isOpen={isOpenFirst}>
            <Card>
              <CardBody>
                Avian For India is more than an organization. It is a grass root
                movement that empowers the individual whether it is a volunteer
                or a donar to make a positive difference in the life of child
                and women and provide them with the possibility of a brighter
                future.
              </CardBody>
            </Card>
          </Collapse>
        </Row>
        <Row className="c-work-even justify-content-end">
          <Button color="info" onClick={toggleSecond}>
            Why the organization name is Avian For India?
          </Button>
          <i
            className="c-work-odd-icon fas fa-hand-point-left"
            aria-hidden="true"
          ></i>
          <Collapse isOpen={isOpenSecond}>
            <Card>
              <CardBody>
                Avian For India got it’s name on certain reasons:
                <ol>
                  <li>
                    <i className="fas fa-hand-point-right"></i> Our main motive
                    is to provide basic education to the children and fight for
                    the Rights of the children and women.
                  </li>
                  <li>
                    <i className="fas fa-hand-point-right"></i> Avian means
                    “BIRD” which indicates towards higher aim. It is a
                    non-profit, non religious and non political organization,
                    which ensures working on health, education and development
                    of government schools in rural and urban areas.
                  </li>
                  <li>
                    <i className="fas fa-hand-point-right"></i> Work for the
                    safety of women and empower them by providing legal aid
                    whenever they got exploited. Ensures to strengthen them by
                    making them aware of their rights.
                  </li>
                </ol>
              </CardBody>
            </Card>
          </Collapse>
        </Row>
        <Row className="c-work-even justify-content-start">
          <i
            className="c-work-even-icon fas fa-hand-point-right"
            aria-hidden="true"
          ></i>
          <Button color="primary" onClick={toggleThird}>
            Does Avian For India staff get paid?
          </Button>
          <Collapse isOpen={isOpenThird}>
            <Card>
              <CardBody>
                No, all members of the Avian work on their will for social
                welfare of the society. They do not get paid.
              </CardBody>
            </Card>
          </Collapse>
        </Row>
        <Row className="c-work-even justify-content-end">
          <Collapse isOpen={isOpenFourth}>
            <Card>
              <CardBody>
                We do not receive any type of funding from the government.
              </CardBody>
            </Card>
          </Collapse>
          <Button color="info" onClick={toggleFourth}>
            Does Avian For India receive government funding?
          </Button>
          <i
            className="c-work-odd-icon fas fa-hand-point-left"
            aria-hidden="true"
          ></i>
        </Row>
        <Row className="c-work-even justify-content-start">
          <i
            className="c-work-even-icon fas fa-hand-point-right"
            aria-hidden="true"
          ></i>
          <Button color="primary" onClick={toggleFifth}>
            Does Avian For India work in India or in other countries too?
          </Button>
          <Collapse isOpen={isOpenFifth}>
            <Card>
              <CardBody>
                Right now we only work in India. With our glaring aim we will
                spread all over the world.
              </CardBody>
            </Card>
          </Collapse>
        </Row>
        <Row className="c-work-even justify-content-end">
          <Button color="info" onClick={toggleSixth}>
            How can You donate to Avian For India?
          </Button>
          <i
            className="c-work-odd-icon fas fa-hand-point-left"
            aria-hidden="true"
          ></i>
          <Collapse isOpen={isOpenSixth}>
            <Card>
              <CardBody>
                Go to “DONATE NOW” section of our website’s page and donate
                online. For the donations related queries, ask in mail.
              </CardBody>
            </Card>
          </Collapse>
        </Row>
        <Row className="c-work-even justify-content-start">
          <i
            className="c-work-even-icon fas fa-hand-point-right"
            aria-hidden="true"
          ></i>
          <Button color="primary" onClick={toggleSeventh}>
            How can I work for Avian For India?
          </Button>
          <Collapse isOpen={isOpenSeventh}>
            <Card>
              <CardBody>
                Kindly go to our “CAREER” page to know more about the current
                openings in Avian For India.{" "}
              </CardBody>
            </Card>
          </Collapse>
        </Row>
      </Container>
    </div>
  );
};

export default CoronaPeriod;

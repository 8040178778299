import React from "react";
import "./collab.scss";

export default function Collaborators() {
  return (
    <div id="collab" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Collaborators</h2>
        </div>
        <div
          className="row collab_icons"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/redcliff.jpeg")}
              alt="redcliff"
            />
          </div>

          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/crysta.jpeg")}
              alt="crysta"
            />
          </div>
        </div>

        <div className="section-title">
          <h2>Organisations We Have Worked With</h2>
        </div>
        <div
          className="row collab_icons"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/itexpert.jpeg")}
              alt="itexpert"
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/amity.jpg")}
              alt="amity"
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/uphcsl.jpeg")}
              alt="uphcs"
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/gss.jpeg")}
              alt="gurudwara"
            />
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/gnet.jpeg")}
              alt="gnet"
            />
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/LPU.jpeg")}
              alt="gnet"
            />
          </div>
        </div>

        <div className="section-title">
          <h2>Media Partner</h2>
        </div>
        <div
          className="row collab_icons"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="col-lg-2 col-md-2 col-sm-6 col-6">
            <img
              src={require("../../assets/img/thumbnail/collab/skp.jpeg")}
              alt="satya ki parakh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

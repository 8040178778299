import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./testimonial.scss";
import manan from "../../assets/img/thumbnail/testimonials/manan.jpeg";
import kriti from "../../assets/img/thumbnail/testimonials/kriti.jpeg";
import jai from "../../assets/img/thumbnail/testimonials/jai.jpeg";
import amity from "../../assets/img/thumbnail/testimonials/amity.png";

const Testimonials = () => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Testimonials</h2>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              autoPlay={true}
            >
              <div className="testi-cnt row">
                <img
                  src={manan}
                  alt="manan"
                  className="col-lg-2 col-md-2 col-sm-4 col-12"
                />
                <p className="testi-msg col-lg-10 col-md-10 col-sm-8 col-12">
                  The seminar conducted by the NGO (Avian For India) on
                  8-July-2021 was highly impressive. The way of delivering the
                  speech and information was so good. The data shared by the
                  team member on the population growth was very informative. The
                  presentation was good, but it could be better by introducing a
                  more attractive way of speech. I would like to suggest you
                  improve the communication among the students. At last, I want
                  to appreciate the communication skills of Mr. Sandeep. He was
                  so good in his speech. As a whole, the seminar was good and I
                  hope you people will work the best in your future.
                  <br />
                  <br />- Manan Sharma, IT Expert, Nilmatha Bazar, Lucknow
                </p>
              </div>

              <div className="testi-cnt row" style={{ display: "flex" }}>
                <img
                  src={kriti}
                  alt="kriti"
                  className="col-lg-2 col-md-2 col-sm-4 col-12"
                />
                <p className="testi-msg col-lg-10 col-md-10 col-sm-8 col-12">
                  It is clear that AVIAN FOR INDIA TRUST is continuously giving
                  their service for 1 year (2020-2021) in the field of covid
                  vaccination and Health awareness program conducted by our
                  UPHC-SADAR under the guidance of NHM. During this period they
                  served several extraordinary works. I wish them a bright
                  future and a successful carrier.
                  <br />
                  <br />- Dr. Kriti Saxena, Medical officer, UPHC Sadar Bazar,
                  Lucknow
                </p>
              </div>
              <div className="testi-cnt row" style={{ display: "flex" }}>
                <img
                  src={jai}
                  alt="kriti"
                  className="col-lg-2 col-md-2 col-sm-4 col-12"
                />
                <p className="testi-msg col-lg-10 col-md-10 col-sm-8 col-12">
                  On the occasion of World Population Day, Avian For India Trust
                  has conducted an awareness program for the students of Gnet
                  Computer Training Institute. Everyone participates
                  enthusiastically in this program. All students and teachers
                  want Avian For India to conduct such a program coming time.
                  Mr. Mrityunjai, (Coordinator - Gnet) extends greeting to Mr.
                  Avinash (Head- Avian For India Trust).
                </p>
              </div>
              <div className="testi-cnt row" style={{ display: "flex" }}>
                <img
                  src={amity}
                  alt="amity"
                  className="col-lg-2 col-md-2 col-sm-4 col-12"
                />
                <p className="testi-msg col-lg-10 col-md-10 col-sm-8 col-12">
                  We want to thank you for giving Intenship Opportunity to B.Ed
                  Students of Amity Institute of Education, AUUP, Lucknow at{" "}
                  <strong>AVIAN FOR INDIA TRUST</strong>. They have gained
                  valuable insight through active participation in{" "}
                  <strong>INDIA AGAINST CHILD ABUSE</strong> awareness program.
                  The guidance provided by you during internship has definitely
                  increased their knowledge and understanding on this relevant
                  and contemporary issue. Once again, thank you for providing
                  rich and varied experience to our students. Looking forward
                  for more association and collaboration in future.
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

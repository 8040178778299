import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
export class features extends Component {
  render() {
    return (
      <div id="features" className="text-center">
        <Container>
          <Col md="12" className="section-title">
            <h2>Our Pillars</h2>
          </Col>
          <Row>
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </Row>
        </Container>
      </div>
    );
  }
}

export default features;

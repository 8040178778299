import React from "react";
import PageHeader from "../Components/common_section/PageHeader";
import Instructions from "../Components/privacy_section/instructions";

export default class Volunteers extends React.Component {

  componentDidMount() {
    this.getlandingPageData();
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }

  handleScrollToAboutUs = () => {
    window.scrollTo(0, 100);
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <PageHeader data={"Privacy Policy"} />
          <Instructions />
        </div>
      </>
    );
  }
}

import cdn1 from "../../assets/img/thumbnail/delhi/a.jpg";
import cdn2 from "../../assets/img/thumbnail/delhi/b.jpg";
import cdn3 from "../../assets/img/thumbnail/delhi/c.jpg";
import cdn4 from "../../assets/img/thumbnail/delhi/d.jpg";
import cdn5 from "../../assets/img/thumbnail/delhi/e.jpg";
import cdn6 from "../../assets/img/thumbnail/delhi/f.jpg";
import cdn7 from "../../assets/img/thumbnail/delhi/g.jpg";
import cdn8 from "../../assets/img/thumbnail/delhi/h.jpg";

import skl1 from "../../assets/img/thumbnail/edu/a.jpg";
import skl2 from "../../assets/img/thumbnail/edu/b.jpg";
import skl3 from "../../assets/img/thumbnail/edu/c.jpg";
import skl4 from "../../assets/img/thumbnail/edu/d.jpg";
import skl5 from "../../assets/img/thumbnail/edu/e.jpg";
import skl6 from "../../assets/img/thumbnail/edu/f.jpg";
import skl7 from "../../assets/img/thumbnail/edu/g.jpg";
import skl8 from "../../assets/img/thumbnail/edu/h.jpg";

import plnt1 from "../../assets/img/thumbnail/orissa/pl-lk-1.jpg";
import plnt2 from "../../assets/img/thumbnail/orissa/pl-lk-2.jpg";
import plnt3 from "../../assets/img/thumbnail/orissa/pl-lk-3.jpg";
import plnt4 from "../../assets/img/thumbnail/orissa/pl-lk-4.jpg";
import plnt5 from "../../assets/img/thumbnail/orissa/pl-lk-5.jpg";
import plnt6 from "../../assets/img/thumbnail/orissa/pl-or-1.jpg";
import plnt7 from "../../assets/img/thumbnail/orissa/pl-or-2.jpg";
import plnt8 from "../../assets/img/thumbnail/orissa/pl-or-3.jpg";
import plnt9 from "../../assets/img/thumbnail/orissa/pl-or-4.jpg";
import plnt10 from "../../assets/img/thumbnail/orissa/pl-or-5.jpg";

import bld1 from "../../assets/img/thumbnail/blood_camp/1.jpg";
import bld2 from "../../assets/img/thumbnail/blood_camp/2.jpg";
import bld4 from "../../assets/img/thumbnail/blood_camp/3.jpg";
import bld3 from "../../assets/img/thumbnail/blood_camp/4.jpg";

import vcc1 from "../../assets/img/thumbnail/vaccination/1.jpg";
import vcc2 from "../../assets/img/thumbnail/vaccination/2.jpg";
import vcc3 from "../../assets/img/thumbnail/vaccination/3.jpg";
import vcc4 from "../../assets/img/thumbnail/vaccination/4.jpg";
import vcc5 from "../../assets/img/thumbnail/vaccination/5.jpg";
import vcc6 from "../../assets/img/thumbnail/vaccination/6.jpg";
import vcc7 from "../../assets/img/thumbnail/vaccination/7.jpg";
import vcc8 from "../../assets/img/thumbnail/vaccination/8.jpg";

import env1 from "../../assets/img/thumbnail/env/1.jpg";
import env2 from "../../assets/img/thumbnail/env/2.jpg";
import env3 from "../../assets/img/thumbnail/env/3.jpg";
import env4 from "../../assets/img/thumbnail/env/4.jpg";
import env5 from "../../assets/img/thumbnail/env/5.jpg";
import env6 from "../../assets/img/thumbnail/env/6.jpg";
import env7 from "../../assets/img/thumbnail/env/7.jpg";

import covid1 from "../../assets/img/thumbnail/covid/1.jpg";
import covid2 from "../../assets/img/thumbnail/covid/2.jpg";
import covid3 from "../../assets/img/thumbnail/covid/3.jpg";
import covid4 from "../../assets/img/thumbnail/covid/4.jpg";
import covid5 from "../../assets/img/thumbnail/covid/5.jpg";
import covid6 from "../../assets/img/thumbnail/covid/6.jpg";
import covid7 from "../../assets/img/thumbnail/covid/7.jpg";

import vccb1 from "../../assets/img/thumbnail/vccb/1.jpg";
import vccb2 from "../../assets/img/thumbnail/vccb/2.jpg";
import vccb3 from "../../assets/img/thumbnail/vccb/3.jpg";
import vccb4 from "../../assets/img/thumbnail/vccb/4.jpg";
import vccb5 from "../../assets/img/thumbnail/vccb/5.jpg";

import pop1 from "../../assets/img/thumbnail/pop/1.jpg";
import pop2 from "../../assets/img/thumbnail/pop/2.jpg";
import pop3 from "../../assets/img/thumbnail/pop/3.jpg";
import pop4 from "../../assets/img/thumbnail/pop/4.jpg";
import pop5 from "../../assets/img/thumbnail/pop/5.jpg";
import pop6 from "../../assets/img/thumbnail/pop/6.jpg";
import pop7 from "../../assets/img/thumbnail/pop/7.jpg";

import musk1 from "../../assets/img/thumbnail/muskurahat/1.jpg";
import musk2 from "../../assets/img/thumbnail/muskurahat/2.jpg";
import musk3 from "../../assets/img/thumbnail/muskurahat/3.jpg";
import musk4 from "../../assets/img/thumbnail/muskurahat/4.jpg";
import musk5 from "../../assets/img/thumbnail/muskurahat/5.jpg";

import vccm1 from "../../assets/img/thumbnail/vaccination/1.jpg";
import vccm2 from "../../assets/img/thumbnail/vaccination/2.jpg";
import vccm3 from "../../assets/img/thumbnail/vaccination/3.jpg";
import vccm4 from "../../assets/img/thumbnail/vaccination/4.jpg";
import vccm5 from "../../assets/img/thumbnail/vaccination/5.jpg";
import vccm6 from "../../assets/img/thumbnail/vaccination/6.jpg";

import gnet1 from "../../assets/img/thumbnail/gnet/1.jpg";
import gnet2 from "../../assets/img/thumbnail/gnet/2.jpg";
import gnet3 from "../../assets/img/thumbnail/gnet/3.jpg";
import gnet4 from "../../assets/img/thumbnail/gnet/4.jpg";
import gnet5 from "../../assets/img/thumbnail/gnet/5.jpg";

import itx1 from "../../assets/img/thumbnail/itexpert/1.jpg";
import itx2 from "../../assets/img/thumbnail/itexpert/2.jpg";
import itx3 from "../../assets/img/thumbnail/itexpert/3.jpg";
import itx4 from "../../assets/img/thumbnail/itexpert/4.jpeg";
import itx5 from "../../assets/img/thumbnail/itexpert/5.jpeg";

import sai1 from "../../assets/img/thumbnail/saipublic/1.jpg";
import sai2 from "../../assets/img/thumbnail/saipublic/2.jpg";
import sai4 from "../../assets/img/thumbnail/saipublic/4.jpg";
import sai5 from "../../assets/img/thumbnail/saipublic/5.jpeg";

import vin1 from "../../assets/img/thumbnail/vindyavasni/1.jpg";
import vin2 from "../../assets/img/thumbnail/vindyavasni/2.jpg";
import vin3 from "../../assets/img/thumbnail/vindyavasni/3.jpg";
import vin4 from "../../assets/img/thumbnail/vindyavasni/4.jpg";
import vin5 from "../../assets/img/thumbnail/vindyavasni/5.jpeg";

import yog1 from "../../assets/img/thumbnail/yoga/1.jpeg";
import yog2 from "../../assets/img/thumbnail/yoga/2.jpeg";
import yog3 from "../../assets/img/thumbnail/yoga/3.jpeg";
import yog4 from "../../assets/img/thumbnail/yoga/4.jpeg";
import yog5 from "../../assets/img/thumbnail/yoga/5.jpeg";

import tpe1 from '../../assets/img/thumbnail/earthday23/1.jpg';
import tpe2 from '../../assets/img/thumbnail/earthday23/2.jpg';
import tpe3 from '../../assets/img/thumbnail/earthday23/3.jpg';
import tpe4 from '../../assets/img/thumbnail/earthday23/4.jpg';
import tpe5 from '../../assets/img/thumbnail/earthday23/5.jpg';

import cdd1 from '../../assets/img/thumbnail/cdd/1.jpg';
import cdd2 from '../../assets/img/thumbnail/cdd/2.jpg';
import cdd3 from '../../assets/img/thumbnail/cdd/3.jpg';
import cdd4 from '../../assets/img/thumbnail/cdd/4.jpg';
import cdd5 from '../../assets/img/thumbnail/cdd/5.jpg';

export const cdds = [
	{original: cdd1, thumbnail: cdd1},
	{original: cdd2, thumbnail: cdd2},
	{original: cdd3, thumbnail: cdd3},
	{original: cdd4, thumbnail: cdd4},
	{original: cdd5, thumbnail: cdd5}
]
export const tpe = [
	{original: tpe1, thumbnail: tpe1},
	{original: tpe2, thumbnail: tpe2},
	{original: tpe3, thumbnail: tpe3},
	{original: tpe4, thumbnail: tpe4},
	{original: tpe5, thumbnail: tpe5},
]
export const yoga = [
	{original: yog1, thumbnail: yog1},
	{original: yog2, thumbnail: yog2},
	{original: yog3, thumbnail: yog3},
	{original: yog4, thumbnail: yog4},
	{original: yog5, thumbnail: yog5},
];

export const internship = [
	{original: vin1, thumbnail: vin1},
	{original: vin2, thumbnail: vin2},
	{original: vin3, thumbnail: vin3},
	{original: vin4, thumbnail: vin4},
	{original: vin5, thumbnail: vin5},
	{original: sai1, thumbnail: sai1},
	{original: sai2, thumbnail: sai2},
	{original: sai4, thumbnail: sai4},
	{original: sai5, thumbnail: sai5},
	{original: itx1, thumbnail: itx1},
	{original: itx2, thumbnail: itx2},
	{original: itx3, thumbnail: itx3},
	{original: itx4, thumbnail: itx4},
	{original: itx5, thumbnail: itx5},
	{original: gnet1, thumbnail: gnet1},
	{original: gnet2, thumbnail: gnet2},
	{original: gnet3, thumbnail: gnet3},
	{original: gnet4, thumbnail: gnet4},
	{original: gnet5, thumbnail: gnet5},
];

export const vccm = [
	{original: vccm1, thumbnail: vccm1},
	{original: vccm2, thumbnail: vccm2},
	{original: vccm3, thumbnail: vccm3},
	{original: vccm4, thumbnail: vccm4},
	{original: vccm5, thumbnail: vccm5},
	{original: vccm6, thumbnail: vccm6},
];

export const musk = [
	{original: musk1, thumbnail: musk1},
	{original: musk2, thumbnail: musk2},
	{original: musk3, thumbnail: musk1},
	{original: musk4, thumbnail: musk4},
	{original: musk5, thumbnail: musk5},
];
export const cdn = [
	{original: cdn1, thumbnail: cdn1},
	{original: cdn2, thumbnail: cdn2},
	{original: cdn3, thumbnail: cdn3},
	{original: cdn4, thumbnail: cdn4},
	{original: cdn5, thumbnail: cdn5},
	{original: cdn6, thumbnail: cdn6},
	{original: cdn7, thumbnail: cdn7},
	{original: cdn8, thumbnail: cdn8},
];

export const skl = [
	{original: skl1, thumbnail: skl1},
	{original: skl2, thumbnail: skl2},
	{original: skl3, thumbnail: skl3},
	{original: skl4, thumbnail: skl4},
	{original: skl5, thumbnail: skl5},
	{original: skl6, thumbnail: skl6},
	{original: skl7, thumbnail: skl7},
	{original: skl8, thumbnail: skl8},
];

export const plnt = [
	{original: plnt1, thumbnail: plnt1},
	{original: plnt2, thumbnail: plnt2},
	{original: plnt3, thumbnail: plnt3},
	{original: plnt4, thumbnail: plnt4},
	{original: plnt5, thumbnail: plnt5},
	{original: plnt6, thumbnail: plnt6},
	{original: plnt7, thumbnail: plnt7},
	{original: plnt8, thumbnail: plnt8},
	{original: plnt9, thumbnail: plnt9},
	{original: plnt10, thumbnail: plnt10},
];

export const bld = [
	{original: bld1, thumbnail: bld1},
	{original: bld2, thumbnail: bld2},
	{original: bld3, thumbnail: bld3},
	{original: bld4, thumbnail: bld4},
];

export const vcc = [
	{original: vcc1, thumbnail: vcc1},
	{original: vcc2, thumbnail: vcc2},
	{original: vcc3, thumbnail: vcc3},
	{original: vcc4, thumbnail: vcc4},
	{original: vcc5, thumbnail: vcc5},
	{original: vcc6, thumbnail: vcc6},
	{original: vcc7, thumbnail: vcc7},
	{original: vcc8, thumbnail: vcc8},
];

export const env = [
	{original: env5, thumbnail: env5},
	{original: env1, thumbnail: env1},
	{original: env2, thumbnail: env2},
	{original: env3, thumbnail: env3},
	{original: env4, thumbnail: env4},
	{original: env6, thumbnail: env6},
	{original: env7, thumbnail: env7},
];

export const covid = [
	{original: covid1, thumbnail: covid1},
	{original: covid2, thumbnail: covid2},
	{original: covid3, thumbnail: covid3},
	{original: covid4, thumbnail: covid4},
	{original: covid5, thumbnail: covid5},
	{original: covid6, thumbnail: covid6},
	{original: covid7, thumbnail: covid7},
];

export const vccb = [
	{original: vccb1, thumbnail: vccb1},
	{original: vccb2, thumbnail: vccb2},
	{original: vccb3, thumbnail: vccb3},
	{original: vccb4, thumbnail: vccb4},
	{original: vccb5, thumbnail: vccb5},
];

export const pop = [
	{original: pop1, thumbnail: pop1},
	{original: pop2, thumbnail: pop2},
	{original: pop3, thumbnail: pop3},
	{original: pop4, thumbnail: pop4},
	{original: pop5, thumbnail: pop5},
	{original: pop6, thumbnail: pop6},
	{original: pop7, thumbnail: pop7},
];

import React, { Component } from "react";
import { Row, Container } from "reactstrap";

export default class Instructions extends Component {
  render() {
    return (
      <div id="instructions">
        <Container className="container_section">
          <Row>
            <h2>Disclaimer</h2>
            <p>
              This website is maintained by AVIAN FOR INDIA TRUST,
              not-for-profit organization started by IAS ASPIRANTS, to deliver
              in social welfare and upliftment of the society with their
              expertise, with a mission to deliver quality education to children
              from under-served communities and villages.
              <br />
              <br />
              <strong>
                AVIAN for India’s mainly focuses into five areas :-{" "}
              </strong>
              <ol>
                <li>
                  1. Education to every children who do not even reach to basic
                  education
                </li>
                <li>2. Health facilities to the poor section of the society</li>
                <li>
                  3. Empowering girl students from rural areas to complete their
                  education and empowering and training women to get stand on
                  their feet and earn with dignity
                </li>
                <li>
                  4. Legal aid to that section of the society who are deprived
                  and underprivileged,
                </li>
                <li>5. Sustainable development for the environment.</li>
              </ol>
            </p>
            <p>
              The information available on website of Avian for India Trust and
              while we endeavour to keep the information up-to-date and correct,
              we make no representations of any kind, express or implied, about
              the completeness, accuracy, reliability, suitability or
              availability with respect to the website or the information and
              related graphics contained on the website for any purpose. Any
              reliance you place on such information is therefore strictly at
              your own risk.
            </p>
            <p>
              We will not be liable for any loss or damage including without
              limitation, indirect or consequential loss or damage, or any loss
              or damage whatsoever arising from loss of data or profits arising
              out of or in connection with the use of this website.
            </p>
            <p>
              Through this website, you are able to link to other websites which
              are not under the control of Avian for India Trust. We have no
              control over the nature, content, and availability of those sites.
              The inclusion of any links does not necessarily imply a
              recommendation or endorse the views expressed within them.
            </p>
          </Row>
        </Container>
        <Container className="container_section">
          <Row>
            <h3>1. General Disclaimer</h3>
            <p>
              The materials provided by trust are without any kind of warranty,
              the trust will not be liable for the products warranty and
              guarantee. Does not entertain any disclaimer product, expressed or
              implied, including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose. Gathering and
              Using of information and features on this site are at user’s sole
              risk. Avian for India Trust, will in no event be liable to any
              person or entity for any direct, indirect, consequential,
              incidental or other damages under any provision / interpretation,
              for any errors in the information and the features on this site.
            </p>
          </Row>
        </Container>
        <Container className="container_section">
          <Row>
            <h3>2. Accuracy of Information</h3>
            <p>
              This website has been compiled in good faith by AVIAN for India
              Trust, but no representation is made or warranty given (either
              express or implied) as to the completeness or accuracy of the
              information it contains. No representations, warranties or
              guarantees whatsoever are made as to the accuracy, adequacy,
              reliability, completeness, suitability or applicability of the
              information to a particular situation. Care has been taken in the
              compilation of this website. The statements contained on this site
              are believed to be correct. However, Avian for India Trust, shall
              not be liable for any loss, damage or inconvenience arising from
              inaccuracies. By accessing this website, you agree that Avian for
              India Trust will not be liable for any direct or indirect loss
              arising from the use of the information and the material contained
              in this website.
            </p>
            <p>
              Site Content: Any information browsed, downloaded or otherwise
              obtained through the use of this site is done at your own
              discretion and risk. Although Avian for India Trust takes full
              precaution and care against viruses in the content present or sent
              through the website, Avian for India Trust, does not warrant that
              this site, its servers, or any mail sent from Avian for India
              Trust, will not have viruses or harmful components. It will not be
              the responsibility of Avian for India Trust, if any damage is
              caused to user’s computer system or any losses occur.
            </p>
          </Row>
        </Container>
        <Container className="container_section">
          <h2>Privacy Policy</h2>
          <h3>Information Gathering</h3>
          <ol>
            <li>
              <strong>
                1. Avian for India Trust collects information from the users in
                a number of ways, for example when the user:
                <blockquote>
                  <p>
                    <li>(i) Makes a donation</li>
                    <li>(ii) Signs up for a campaign</li>
                    <li>(iii) Signs up to stay updated</li>
                  </p>
                </blockquote>
              </strong>
            </li>
            <br />
            <li>
              <strong>
                2. While donating to Avian for India Trust, the donors have to
                submit some of his/her personal information as it would help us
                ensuring genuine contributions:
                <blockquote>
                  <p>
                    <li>(i) Name</li>
                    <li>(ii) Email and mailing address</li>
                    <li>(iii) Contact number</li>
                    <li>(iv) Donor’s payment processing details</li>
                    <li>(v) Any other data as required</li>
                  </p>
                </blockquote>
              </strong>
            </li>
            <br />
            <li>
              <strong>
                3. Avian for India Trust does not collect or record the user’s
                personal information unless he/she chooses to provide it.
              </strong>
            </li>
          </ol>
          <p></p>
          <h3>Use of Personal Information</h3>
          <p>
            1. General browsing of Avian for India Trust website is anonymous
            and it does not register the user's personal information except the
            time, date and place of visits and the name of internet service
            provider. This data is used only for statistics and diagnosis.
          </p>
          <p>
            2. By signing up for various services offered by Avian for India
            Trust, the user explicitly authorizes us to collect information
            based on the user's usage. The information is used to help provide a
            better experience to the user and is used as per the user’s
            specified instructions.
          </p>
          <p>
            3. Avian for India Trust keeps the user information strictly
            confidential and this information is safely secured. All relevant
            information collected through Avian for India Trust website is
            handled and used by internal and/or authorized officials only. It is
            never shared with any external agencies or third party individuals.
          </p>
          <p>
            4. Avian for India Trust uses the information given to it in the
            following ways: (i) To keep an accurate record of all the donations
            received (ii) To update users about its happenings and developments
            through bulletins and newsletters, with an option of not to
            subscribe for the same (iii) To make sure the user is receiving the
            most appropriate and relevant information. (iv) To find out more
            about the people who are visiting the Avian for India Trust website,
            donating, or joining its campaigns.
          </p>
          <p>
            5. Usually, Avian for India Trust does not store user data. In case
            of specific sign-ups, the data is stored as per user request. The
            user can opt to delete all the information he/she has provided by
            simply requesting such by mail. All information, without exception,
            will be deleted in two working days.
          </p>
          <h3>Privacy of e-mail lists</h3>
          <p>
            Individuals who join Avian for India Trust mailing lists via its
            website or through its campaigning engagements are added to its
            email database. Smile Foundation does not sell, rent, loan, trade,
            or lease the addresses on our lists to anyone.
          </p>
          <h3>Cookie Policy</h3>
          <p>
            1. Cookies are pieces of electronic information which will be sent
            by Avian for India Trust when a user visits the website. These will
            be placed in the hard disk of the user’s computer and enable Avian
            for India Trust to recognise the user when he/she visits the website
            again.
          </p>
          <p>
            2. The user can configure his/her browser so that it responds to
            cookies the way he/she deems fit. For example, you make want to
            accept all cookies, reject them all or get notified when a cookie is
            sent. The users may check their browser’s settings to modify cookie
            behaviour as per individual behaviour.
          </p>
          <p>
            3. If a user disables the use of cookies on the web browser, or
            removes or rejects specific cookies from Avian for India’s website
            or linked sites then he/she may not be able to use the website as it
            is intended.
          </p>
          <h3>Payment Gateway</h3>
          <p>
            1. Avian for India Trust uses well-recognised and proven technology
            for payments. Payment information is transferred by the use of an
            SSL connection which offers the highest degree of security that the
            donor’s browser is able to support.
          </p>
          <p>
            2. Several layers of built-in security, including an advanced
            firewall system, encryption of credit card numbers, and use of
            passwords, protect the collected information.
          </p>
          <h3>Changes to Privacy Policy</h3>
          <p>
            1. As and when the need arises, Avian for India Trust may alter its
            privacy policy in accordance with the latest technology and trends.
            It will provide you with timely notice of these changes. The users
            may reach out to Smile Foundation if they have any queries about any
            changes made to its practices.
          </p>
          <p>
            2. If you have any questions at all about Avian for India Trust
            privacy policy, please write to us at: avianforindia@gmail.com
          </p>
          <h3>Refund and Cancellation Policy</h3>
          Welcome to this web-site of Avian for India Trust. We make public our
          policy on refund and cancellation of donations received for the social
          cause on payment gateway as under:-
          <p>
            1. No refund/cancellation for the donated amount by any donor will
            not be entertained, the online donations through the online payment
            gateway.
          </p>
          <p>2. No cash or refund of money will be allowed.</p>
          <p>
            3. If any in-kind support received by the donor from any where the
            material will be reached to the poorest of the poorer communities.
          </p>
          <p>
            4. Once received the donation for a cause will not be refunded to
            the donor. No cancellation to be made. The donation will be used for
            the community development, children education or women’s
            empowerment.
          </p>
          <h3>Terms and Conditions</h3>
          <p>
            Use of this site is provided by Avian for India Trust subject to the
            following Terms and Conditions:
          </p>
          <p>
            Avian for India Trust reserves the rights to change these terms and
            conditions at any time by posting changes online. Your continued use
            of this site after changes are posted constitutes your acceptance of
            this agreement as modified. You agree to use this site only for
            lawful purposes, and in a manner which does not infringe the rights,
            or restrict, or inhibit the use and enjoyment of the site by any
            third party.
          </p>
          <p>
            This site and the information, names, images, pictures, logos
            regarding or relating to Avian for India Trust are provided “as is”
            without any representation or endorsement made and without warranty
            of any kind whether express or implied. In no event will Avian for
            India Trust be liable for any damages including, without limitation,
            indirect or consequential damages, or any damages whatsoever arising
            from the use or in connection with such use or loss of use of the
            site, whether in contract or in negligence. Avian for India Trust
            does not warrant that the functions contained in the material
            contained in this site will be uninterrupted or error free, that
            defects will be corrected, or that this site or the server that
            makes it available are free of viruses or bugs or represents the
            full functionality, accuracy and reliability of the materials.
          </p>
          <h3>Copyright restrictions:</h3>
          <p>
            Commercial use or publication of all or any item displayed is
            strictly prohibited without prior authorization from Avian for India
            Trust. Nothing contained herein shall be construed as conferring any
            license by Avian for India Trust to use any item displayed.
            Documents may be copied for personal use only on the condition that
            copyright and source indications are also copied, no modifications
            are made and the document is copied entirely. However, some
            documents and photos have been published on this site with the
            permission of the relevant copyright owners (who are not Avian for
            India Trust). All rights are reserved on these documents and
            permission to copy them must be requested from the copyright owners
            (the sources are indicated within these documents/photographs).
          </p>
          <p>
            Avian for India Trust takes no responsibility for the content of
            external Internet sites. Other websites that we link to are owned
            and operated by third parties and Avian for India Trust has no
            control over them. The fact that we include links to other websites
            does not mean that Avian for India Trust approves of or endorses any
            other third party website or the content of that website. We accept
            no liability for any statements, information, products or services
            that are published on or are accessible through any websites owned
            or operated by third parties.
          </p>
          <p>
            Any communication or material that you transmit to, or post on, any
            public area of the site including any data, questions, comments,
            suggestions, or the like, is, and will be treated as, non-
            confidential and nonproprietary information. If there is any
            conflict between these terms and conditions and rules and/or
            specific terms of use appearing on this site relating to specific
            material then the latter shall prevail.
          </p>
          These terms and conditions shall be governed and construed in
          accordance with the laws of India. If these terms and conditions are
          not accepted in full, the use of this site must be terminated
          immediately. Avian for India Trust for Social Welfare Service is a
          registered at <br />
          <strong>
            Army complex Diptyganj, Nilmatha, Hariharpur Nagram Road, near
            Shaheed path Lucknow 226002.
            <br />
            Whatsapp : +91 8960416311
            <br />
            Calling : +91 8707739346
          </strong>
        </Container>
      </div>
    );
  }
}

import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";

export default function volunteer() {
  return (
    <div className="wrapper">
      <div className="pageheader-landing">
        <div className="pageheader-bg">
          <div className="pageheader-overlay" />
          <div className="pageheader-award-img" />
          <div className="pageheader-content">
            <h2>Volunteers</h2>
          </div>
        </div>
      </div>
      <div className="container" style={{ padding: "2rem 0" }}>
        <div className="row">
          <div className="col-lg-4">
            <Card>
              <CardBody>
                <img
                  src={require("../../assets/img/volunteer/abhachoudhary.jpg")}
                  style={{ width: "100%" }}
                  alt="abha choudhary"
                />
              </CardBody>
              <CardFooter>Abha Choudhary</CardFooter>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card>
              <CardBody>
                <img
                  src={require("../../assets/img/volunteer/richatiwari.jpg")}
                  style={{ width: "100%" }}
                  alt="richatiwari"
                />
              </CardBody>
              <CardFooter>Richa Tiwari</CardFooter>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card>
              <CardBody>
                <img
                  src={require("../../assets/img/volunteer/surabhisomvanshi.jpg")}
                  style={{ width: "100%" }}
                  alt="surabhi somvanshi"
                />
              </CardBody>
              <CardFooter>Surabhi Somvanshi</CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

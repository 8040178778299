import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";

export default class Founder extends Component {
  render() {
    return (
      <div id="founder">
        <Container>
          <Row className="founder-body">
            <Col lg="12">
              <h2>From Founder's Pen</h2>
            </Col>
            <Col xs="12" md="6" className="about-img">
              <img src={require(`../../assets/img/${31}.jpg`)} alt="award" style={{width: '80%'}} />
             </Col>
            <Col xs="12" md="6">
              <div className="about-text">
                <p>
                  I believe that Children are our future. Our Responsiblity is
                  to teach them well and let them lead the way. Show them all
                  the beauty that they posses inside and give them a sense of
                  pride.
                </p>
                <p>
                  Let the Children's laughter reminds us what we used to be.
                </p>
                <p>
                  <strong>- Avinash Kumar Raut</strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

import React from "react";
import PageHeader from "../Components/common_section/PageHeader";
import EventsList from "../Components/happenings_section/events_list";
import EventsJsonData from "../data/events.json";

export default class Happenings extends React.Component {
	state = {
		eventsPageData: [],
	};

	getlandingPageData() {
		this.setState({eventsPageData: EventsJsonData.Events.reverse()});
	}

	componentDidMount() {
		this.getlandingPageData();
		document.body.classList.add("index-page");
		document.body.classList.add("sidebar-collapse");
		document.documentElement.classList.remove("nav-open");
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove("index-page");
			document.body.classList.remove("sidebar-collapse");
		};
	}

	handleScrollToAboutUs = () => {
		window.scrollTo(0, 100);
	};

	render() {
		return (
			<>
				<div className="wrapper">
					<PageHeader data={"Happenings"} />
					<EventsList data={this.state.eventsPageData} />
				</div>
			</>
		);
	}
}

import React, { Component } from "react";

export class Trustees extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-12">
                    <div className="testimonial">
                      <div className="testimonial-image">
                        {" "}
                        <img
                          src={require(`../../assets/img/team/${d.img}.jpg`)}
                          alt=""
                        />
                      </div>
                      <div className="testimonial-content">
                        <p>"{d.text}"</p>
                        <div className="testimonial-meta"> - {d.name} </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Trustees;

import React, { Component } from "react";
import { Container } from "reactstrap";
import Gallery from "react-grid-gallery";
import img1 from "../../assets/img/thumbnail/vaccination/1.jpg";
import img2 from "../../assets/img/thumbnail/vaccination/2.jpg";
import img3 from "../../assets/img/services/women.jpg";
import img4 from "../../assets/img/thumbnail/yoga/1.jpeg";
import img5 from "../../assets/img/thumbnail/yoga/2.jpeg";
import img6 from "../../assets/img/thumbnail/yoga/3.jpeg";
import img7 from "../../assets/img/thumbnail/saipublic/5.jpeg";
import img8 from "../../assets/img/thumbnail/gnet/5.jpg";
import img9 from "../../assets/img/thumbnail/itexpert/1.jpg";
import img10 from "../../assets/img/thumbnail/vindyavasni/1.jpg";
import img11 from "../../assets/img/thumbnail/env/1.jpg";
import img12 from "../../assets/img/thumbnail/env/2.jpg";
import img13 from "../../assets/img/thumbnail/delhi/g.jpg";
import img14 from "../../assets/img/thumbnail/delhi/h.jpg";

import img15 from "../../assets/img/thumbnail/infertility/1.jpeg";
import img16 from '../../assets/img/thumbnail/earthday23/1.jpg';
import img17 from '../../assets/img/thumbnail/earthday23/2.jpg';
import img20 from '../../assets/img/thumbnail/earthday23/3.jpg';
import img21 from '../../assets/img/thumbnail/earthday23/4.jpg';
import img22 from '../../assets/img/thumbnail/earthday23/5.jpg';
import img18 from "../../assets/img/thumbnail/infertility/4.jpeg";
import img19 from "../../assets/img/thumbnail/infertility/5.jpeg";


const IMAGES = [
  {
    src: img22,
    thumbnail: img22,
    thumbnailWidth: 1200,
    thumbnailHeight: 765,
    isSelected: false,
    caption: "",
  },
  {
    src: img21,
    thumbnail: img21,
    thumbnailWidth: 700,
    thumbnailHeight: 394,
    isSelected: false,
    caption: "",
  },
  {
    src: img20,
    thumbnail: img20,
    thumbnailWidth: 496,
    thumbnailHeight: 700,
    isSelected: false,
    caption: "",
  },
  {
    src: img17,
    thumbnail: img17,
    thumbnailWidth: 700,
    thumbnailHeight: 456,
    isSelected: false,
    caption: "",
  },
  {
    src: img16,
    thumbnail: img16,
    thumbnailWidth: 700,
    thumbnailHeight: 394,
    isSelected: false,
    caption: "",
  },
  {
    src: img15,
    thumbnail: img15,
    thumbnailWidth: 1280,
    thumbnailHeight: 853,
    isSelected: false,
    caption: "",
  },
  {
    src: img18,
    thumbnail: img18,
    thumbnailWidth: 1280,
    thumbnailHeight: 853,
    isSelected: false,
    caption: "",
  },
  {
    src: img19,
    thumbnail: img19,
    thumbnailWidth: 1280,
    thumbnailHeight: 853,
    isSelected: false,
    caption: "",
  },
  {
    src: img1,
    thumbnail: img1,
    thumbnailWidth: 1280,
    thumbnailHeight: 720,
    isSelected: false,
    caption: "",
  },
  {
    src: img2,
    thumbnail: img2,
    thumbnailWidth: 1440,
    thumbnailHeight: 1029,
    isSelected: false,
    caption: "",
  },
  {
    src: img3,
    thumbnail: img3,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img4,
    thumbnail: img4,
    thumbnailWidth: 1280,
    thumbnailHeight: 960,
    isSelected: false,
    caption: "",
  },

  {
    src: img5,
    thumbnail: img5,
    thumbnailWidth: 1280,
    thumbnailHeight: 960,
    isSelected: false,
    caption: "",
  },
  {
    src: img6,
    thumbnail: img6,
    thumbnailWidth: 1280,
    thumbnailHeight: 960,
    isSelected: false,
    caption: "",
  },
  {
    src: img7,
    thumbnail: img7,
    thumbnailWidth: 1600,
    thumbnailHeight: 720,
    isSelected: false,
    caption: "",
  },

  {
    src: img8,
    thumbnail: img8,
    thumbnailWidth: 1600,
    thumbnailHeight: 1200,
    isSelected: false,
    caption: "",
  },
  {
    src: img9,
    thumbnail: img9,
    thumbnailWidth: 4624,
    thumbnailHeight: 3468,
    isSelected: false,
    caption: "",
  },
  {
    src: img10,
    thumbnail: img10,
    thumbnailWidth: 4624,
    thumbnailHeight: 2084,
    isSelected: false,
    caption: "",
  },
  {
    src: img11,
    thumbnail: img11,
    thumbnailWidth: 1000,
    thumbnailHeight: 1000,
    isSelected: false,
    caption: "",
  },
  {
    src: img12,
    thumbnail: img12,
    thumbnailWidth: 1280,
    thumbnailHeight: 1280,
    isSelected: false,
    caption: "",
  },
  {
    src: img13,
    thumbnail: img13,
    thumbnailWidth: 1200,
    thumbnailHeight: 960,
    isSelected: false,
    caption: "",
  },
  {
    src: img14,
    thumbnail: img14,
    thumbnailWidth: 1280,
    thumbnailHeight: 1024,
    isSelected: false,
    caption: "",
  },
];

export class GalleryImages extends Component {
  render() {
    return (
      <div
        id="portfolio"
        className="text-center"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="section-title">
            <h2>Gallery</h2>
            <p>
              It is not enough to be compassionate. You must act. - Dalai Lama
            </p>
          </div>
          <div className="gallery-images" style={{ height: 600 }}>
            <Gallery images={IMAGES} rowHeight={200} maxRows={3} />
          </div>
        </Container>
      </div>
    );
  }
}

export default GalleryImages;
